// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as ID from "../../../../../libs/ID.res.js";
import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as Picture from "../../../../../styleguide/forms/Picture/Picture.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Cloudinary from "../../../../../libs/Cloudinary.res.js";
import * as IconUserAvatar from "../../../../../styleguide/icons/IconUserAvatar.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsContactInformationScss from "./UserProjectDetailsContactInformation.scss";

var css = UserProjectDetailsContactInformationScss;

function UserProjectProposalProviderManagersInformation$ManagerDetail(props) {
  var manager = props.manager;
  var image = manager.profilePic;
  var tmp = image === "" ? JsxRuntime.jsx(IconUserAvatar.make, {
          size: "XXXL"
        }) : JsxRuntime.jsx(Picture.make, {
          src: Cloudinary.imageUrl(image),
          large: [
            60,
            60
          ],
          crop: "Fill",
          imageClassName: css.profilePic,
          fallbackSrc: Cloudinary.imageUrl("static/profile-avatar")
        });
  var jobTitle = manager.jobTitle;
  var tmp$1 = jobTitle === "" ? null : JsxRuntime.jsx("p", {
          children: jobTitle,
          className: css.designationText
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: tmp,
                      className: css.imageWrapper
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("h5", {
                                    children: manager.firstName + (" " + manager.lastName),
                                    className: css.name
                                  }),
                              className: css.nameAndTagWrapper
                            }),
                        tmp$1,
                        JsxRuntime.jsx(Link.make, {
                              href: "mailto:" + manager.email,
                              className: css.link,
                              children: manager.email
                            }),
                        JsxRuntime.jsx(Link.make, {
                              href: "tel:" + manager.phoneNumber,
                              className: css.link,
                              children: manager.phoneNumber
                            })
                      ],
                      className: css.conciergeData
                    })
              ],
              className: css.conciergeWrapper
            });
}

var ManagerDetail = {
  make: UserProjectProposalProviderManagersInformation$ManagerDetail
};

function UserProjectProposalProviderManagersInformation(props) {
  var managers = props.managers;
  if (managers.length !== 0) {
    return JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx("h4", {
                        children: "Sales Managers",
                        className: Cx.cx([
                              css.heading,
                              css.managerHeading
                            ])
                      }),
                  Belt_Array.map(managers, (function (manager) {
                          return JsxRuntime.jsx(UserProjectProposalProviderManagersInformation$ManagerDetail, {
                                      manager: manager
                                    }, ID.toString(manager.id));
                        }))
                ],
                className: css.container
              });
  } else {
    return null;
  }
}

var make = UserProjectProposalProviderManagersInformation;

export {
  css ,
  ManagerDetail ,
  make ,
}
/* css Not a pure module */
