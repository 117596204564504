// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconEmployees(props) {
  var color = props.color;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Employees";
  return JsxRuntime.jsxs(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: [
                JsxRuntime.jsx("path", {
                      d: "M6,8 C8.209139,8 10,6.209139 10,4 C10,1.790861 8.209139,0 6,0 C3.790861,0 2,1.790861 2,4 C2,6.209139 3.790861,8 6,8 Z",
                      fill: Icon.mapColor(color)
                    }),
                JsxRuntime.jsx("path", {
                      d: "M6,16 C10.6568542,16 12,15.6568542 12,14 C12,12.3431458 9.6568542,9 6,9 C2.34314575,9 0,12.3431458 0,14 C0,15.6568542 1.34314575,16 6,16 Z",
                      fill: Icon.mapColor(color)
                    })
              ]
            });
}

var make = IconEmployees;

export {
  make ,
}
/* Icon Not a pure module */
