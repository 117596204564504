// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconArrowLeft(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Arrow left";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M5 0l1 1-4 4 4 4-1 1-5-5z",
                    fill: Icon.mapColor(props.color),
                    fillRule: "nonzero"
                  })
            });
}

var make = IconArrowLeft;

export {
  make ,
}
/* Icon Not a pure module */
