// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          name: js.name,
          logo: js.logo,
          numberEmployees: js.numberEmployees,
          yearFounded: js.yearFounded,
          totalFacilities: js.totalFacilities,
          address: js.address,
          slug: js.slug
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              name: field.required("name", Json_Decode$JsonCombinators.string),
              logo: field.required("logo", Json_Decode$JsonCombinators.string),
              numberEmployees: field.required("numberEmployees", Json_Decode$JsonCombinators.$$int),
              yearFounded: field.required("yearFounded", Json_Decode$JsonCombinators.$$int),
              totalFacilities: field.required("totalFacilities", Json_Decode$JsonCombinators.$$int),
              address: field.required("address", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  fromJs ,
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
