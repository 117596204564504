// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Project from "./Project.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", Project.Id.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              businessName: field.required("businessName", Json_Decode$JsonCombinators.string),
              status: field.required("status", Project.Status.decoder)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
