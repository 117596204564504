// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconYear(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Calendar";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M1,2 L3,2 L3,0 L5,0 L5,2 L11,2 L11,0 L13,0 L13,2 L15,2 C15.5522847,2 16,2.44771525 16,3 L16,15 C16,15.5522847 15.5522847,16 15,16 L1,16 C0.44771525,16 0,15.5522847 0,15 L0,3 C0,2.44771525 0.44771525,2 1,2 Z M3,7 L3,9 L5,9 L5,7 L3,7 Z M7,7 L7,9 L9,9 L9,7 L7,7 Z M11,7 L11,9 L13,9 L13,7 L11,7 Z M3,11 L3,13 L5,13 L5,11 L3,11 Z M7,11 L7,13 L9,13 L9,11 L7,11 Z",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = IconYear;

export {
  make ,
}
/* Icon Not a pure module */
