// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../../../models/User.res.js";
import * as React from "react";
import * as Project from "../../../../models/Project.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as RailsContext from "../../../../models/RailsContext.res.js";
import * as ProjectProposal from "../../../../models/ProjectProposal.res.js";
import * as ProjectAsProposal from "../../../../models/ProjectAsProposal.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderAsProposal from "../../../../models/ProviderAsProposal.res.js";
import * as ProjectsViewContext from "../../../../models/ProjectsViewContext.res.js";
import * as UserProjectProposal from "./UserProjectProposal.res.js";
import * as ProjectStickyBarData from "../_components/ProjectStickyBar/ProjectStickyBarData.res.js";

function UserProjectProposal__JsBridge$default(props) {
  var railsContext = props.railsContext;
  var jsProps = props.jsProps;
  var railsContext$1 = React.useMemo((function () {
          return RailsContext.fromJson(railsContext);
        }), [railsContext]);
  var project = React.useMemo((function () {
          return ProjectAsProposal.fromJson(jsProps.project);
        }), [jsProps.project]);
  var proposal = React.useMemo((function () {
          return ProjectProposal.fromJs(jsProps.proposal);
        }), [jsProps.proposal]);
  var provider = React.useMemo((function () {
          return ProviderAsProposal.fromJs(jsProps.provider);
        }), [jsProps.provider]);
  var documents = React.useMemo((function () {
          return Belt_Array.map(jsProps.documents, Project.$$Document.fromJs);
        }), [jsProps.documents]);
  var additionalDocuments = React.useMemo((function () {
          return Belt_Array.map(jsProps.additionalDocuments, Project.$$Document.fromJs);
        }), [jsProps.additionalDocuments]);
  var viewContext = React.useMemo((function () {
          return ProjectsViewContext.fromString(jsProps.viewContext);
        }), [jsProps.viewContext]);
  var projectStickyBarData = React.useMemo((function () {
          return ProjectStickyBarData.fromJson(jsProps.projectStickyBar);
        }), [jsProps.projectStickyBar]);
  var concierges = React.useMemo((function () {
          return Belt_Array.map(jsProps.concierges, User.fromJson);
        }), [jsProps.concierges]);
  var managers = React.useMemo((function () {
          return Belt_Array.map(jsProps.managers, User.fromJson);
        }), [jsProps.managers]);
  return JsxRuntime.jsx(UserProjectProposal.make, {
              project: project,
              concierges: concierges,
              managers: managers,
              proposal: proposal,
              additionalDocuments: additionalDocuments,
              documents: documents,
              provider: provider,
              totalProposals: jsProps.totalProposals,
              canSendProposals: jsProps.canSendProposals,
              viewContext: viewContext,
              userRole: railsContext$1.userRole,
              manageMultipleProviders: jsProps.manageMultipleProviders,
              projectStickyBarData: projectStickyBarData,
              desktop: railsContext$1.desktop,
              tablet: railsContext$1.tablet,
              mobile: railsContext$1.mobile
            });
}

var $$default = UserProjectProposal__JsBridge$default;

export {
  $$default as default,
}
/* User Not a pure module */
