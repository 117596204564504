// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Project from "../../../../models/Project.res.js";
import * as ProjectHeader from "../_components/ProjectHeader/ProjectHeader.res.js";
import * as ProjectNavbar from "../_components/ProjectNavbar/ProjectNavbar.res.js";
import * as ProjectStickyBar from "../_components/ProjectStickyBar/ProjectStickyBar.res.js";
import * as ProjectTopNavbar from "../_components/ProjectTopNavbar/ProjectTopNavbar.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectProposalScss from "./UserProjectProposal.scss";
import * as UserProjectProposal_Details from "./UserProjectProposal_Details.res.js";

var css = UserProjectProposalScss;

function UserProjectProposal(props) {
  var projectStickyBarData = props.projectStickyBarData;
  var userRole = props.userRole;
  var viewContext = props.viewContext;
  var project = props.project;
  var stickyBarUserRole = Project.Role.toRole(projectStickyBarData.userProjectRole);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ProjectTopNavbar.make, {
                      viewContext: viewContext,
                      userRole: userRole,
                      projectStickyBarData: projectStickyBarData
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(ProjectHeader.make, {
                              title: project.title,
                              businessName: project.businessName,
                              userRole: stickyBarUserRole,
                              projectId: project.id,
                              viewContext: viewContext,
                              projectStatus: projectStickyBarData.project.status
                            }),
                        JsxRuntime.jsx(ProjectNavbar.make, {
                              activeTab: "Proposals",
                              userRole: stickyBarUserRole,
                              projectId: project.id,
                              totalProposals: props.totalProposals,
                              viewContext: viewContext
                            }),
                        JsxRuntime.jsx(UserProjectProposal_Details.make, {
                              projectId: project.id,
                              concierges: props.concierges,
                              managers: props.managers,
                              proposal: props.proposal,
                              additionalDocuments: props.additionalDocuments,
                              documents: props.documents,
                              provider: props.provider,
                              userRole: stickyBarUserRole,
                              viewContext: viewContext,
                              manageMultipleProviders: props.manageMultipleProviders,
                              role: userRole
                            }),
                        JsxRuntime.jsx(ProjectStickyBar.make, {
                              userRole: stickyBarUserRole,
                              projectStickyBarData: projectStickyBarData,
                              canSendProposals: props.canSendProposals,
                              viewContext: viewContext,
                              desktop: props.desktop,
                              tablet: props.tablet,
                              mobile: props.mobile
                            })
                      ],
                      className: css.contentWrapper
                    })
              ],
              className: css.container
            });
}

var make = UserProjectProposal;

export {
  css ,
  make ,
}
/* css Not a pure module */
